import React from "react"
import ProductPage from "../components/ProductPage/productPage";
import geokodowanie from '../images/gifs/pp_geokodowanie.gif'
import odwrotne from '../images/gifs/pp_odwrotne.gif'
import { injectIntl, intlShape } from "react-intl";
import { compose } from "recompose";
import withPageContext from "../pageContext";

const data = (intl) => {
  return {
    title: intl.formatMessage({ id: 'geokodowanie.title' }),
    desc: intl.formatMessage({ id: 'geokodowanie.desc' }),
    anchors: ['', intl.formatMessage({ id: 'nav.produkty.geokodowanie.adresy.slug' }), intl.formatMessage({ id: 'nav.produkty.geokodowanie.odwrotne.slug' }), ''],
    sections: [
      {
        title: intl.formatMessage({ id: 'geokodowanie.adresy.title' }),
        desc: intl.formatMessage({ id: 'geokodowanie.adresy.desc' }),
        img: geokodowanie,
        link: null,
        widget: "standaryzacja",
        widgetVersion: 1,
        imageRight: true,
        background: "01_blue.png"
      },
      {
        title: intl.formatMessage({ id: 'geokodowanie.odwrotne.title' }),
        desc: intl.formatMessage({ id: 'geokodowanie.odwrotne.desc' }),
        img: odwrotne,
        link: "https://api.locit.io/docs/?urls.primaryName=geocoding-reversed-v2.2.0",
        target: 'blank',
        widget: null,
        widgetVersion: null,
        imageRight: false,
        background: "02_blue.png"
      }
    ],
    features: [
      {
        text: intl.formatMessage({ id: 'features.crm' }),
        icon: "bi_crm"
      },
      {
        text: intl.formatMessage({ id: 'features.analytics' }),
        icon: "analytics"
      },
      {
        text: intl.formatMessage({ id: 'features.ecommerce' }),
        icon: "ecommerce"
      }
    ]
  }
}

const Geokodowanie = (props) => (
  <ProductPage data={data(props.intl)} uri={props.uri} pathname={props.location.pathname} />
)

Geokodowanie.propTypes = {
  intl: intlShape.isRequired
};

export default compose(
  withPageContext,
  injectIntl
)(Geokodowanie)
